import React, { useEffect } from "react";
import { useState } from "react";
// import useGraphQLQuerie from "../hooks/useGraphQLQuerie";
import SearchBar from "./SearchBar";
import Substance from "./Substance";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

const ProdHubPage = () => {

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState(false);
	const [error, setError] = useState(null);
	const [prod, setProd] = useState(null);

	const query = encodeURIComponent(`
    {
		substances(query: "${prod}") {
			name
    		addictionPotential
    		toxicity
			url
    		crossTolerances
    		commonNames
    		summary
    		featured
			effects {
					name url
				}
			uncertainInteractions {name}
    		unsafeInteractions {name}
    		dangerousInteractions {name}
			class {
				chemical
				psychoactive
			}
			tolerance {
				full
				half
				zero
			}
			roas {
				name
				dose {
					units
					threshold
					heavy
					common { min max }
					light { min max }
					strong { min max }
				}
				duration {
					afterglow { min max units }
					comeup { min max units }
					duration { min max units }
					offset { min max units }
					onset { min max units }
					peak { min max units }
					total { min max units }
				}
				bioavailability {
					min max
				}
			}
		}
	}`);

	useEffect(() => {
		if (!search) return;
		setError(null)
		setLoading(true);
		setTimeout(() => {
			fetch(`https://api.psychonautwiki.org/?query=${query}`, {
			method: "GET",
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.data.substances.length === 0) {
					setError("No prods founds 🤕");
					setLoading(false);
					setSearch(false);
					setData(null);
				} else {
					setData(res.data.substances[0]);
					setLoading(false);
					setSearch(false);
					setError(null);
				}
			})
			.catch((err) => {
				setError(err);
				setLoading(false);
				setSearch(false);
				setData(null);
			});
		  }, 200);
	}, [prod]);


	const handleSubmit = (prod) => {
		if (prod === "" || prod === null) {
			setError("Please enter a prod 😵");
			return;
		}
		setProd(prod);
		setSearch(true);
	}

	return (<div className="flex flex-col items-center bg-background space-y-12 py-4 min-h-app text-white">
		<h1 className="text-6xl font-bold"><span className="text-white">Pocket</span><span className="text-secondary ml-1 p-1">RDR</span></h1>
		<div className="flex flex-col items-center text-lg space-y-4">
			<h2>Simply <strong>search</strong> for any <strong>substances</strong></h2>
			<h3>Try <span className="text-secondary">LSD</span>, <span className="text-secondary">Xanax</span> or <span className="text-secondary">Tramadol</span></h3>
		</div>
		<SpeedInsights/>
		<Analytics/>
		
		<SearchBar handleSubmit={handleSubmit} />

		{error && <><h1 className="text-red text-2xl">{error}</h1></>}
		{loading && 
		<>
			<div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
		</>
		}
		{data && !loading && <Substance data={data} />}

		{/* Footer */}
		<div className="text-primary text-center">
		<h3 className="text-white"><a href="https://top.gg/fr/bot/1232678531591307335">⭐ Discover <span className="text-cyan-600">PocketRDR</span> on <span className="text-cyan-600 underline">Discord</span> ⭐</a></h3>
			<p>Developed with <svg className="inline-block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 12.27 18.6 15.36 13.45 20.03L12 21.35Z" fill="#B54646" />
			</svg> by <strong><a href="https://zyksa.dev">Zyksa</a></strong></p>
		</div>
	</div>)
}

export default ProdHubPage;